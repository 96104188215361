import React from "react";

const IE = () => {
  return (
    <section className="ie">
      <h1>Please Update To A Modern Browser To View This Page</h1>
    </section>
  );
};

export default IE;

import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.scss";
import App from "./App";
import IE from "./IE";
import * as serviceWorker from "./serviceWorker";

if (
  !!navigator.userAgent.match(/Trident/g) ||
  !!navigator.userAgent.match(/MSIE/g)
) {
  ReactDOM.render(<IE />, document.getElementById("root"));
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import Header from "./components/Header";
import Footer from "./components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faJs,
  faCss3,
  faHtml5,
  faAccessibleIcon,
  faReact,
  faNode,
  faJava,
} from "@fortawesome/free-brands-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";

const App = () => {
  const displayGreeting = () => {
    const greetings = [
      "Web Developer",
      "Creative",
      "Coffee Addict",
      "Gamer",
      "Sarcastic",
      "Gym Nerd",
      "Design Lover",
    ];
    const random = Math.floor(Math.random() * greetings.length);
    return greetings[random];
  };

  const displaySkills = () => {
    const skills = [
      {
        icon: faJs,
        name: "JavaScript",
      },
      {
        icon: faReact,
        name: "React",
      },
      {
        icon: faNode,
        name: "Node",
      },
      {
        icon: faHtml5,
        name: "HTML",
      },
      {
        icon: faCss3,
        name: "CSS",
      },
      {
        icon: faJava,
        name: "Java",
      },
      {
        icon: faAccessibleIcon,
        name: "Accessibility",
      },
      {
        icon: faUser,
        name: "User Experience",
      },
    ];
    return skills.map((skill, index) => {
      return (
        <div key={index} className="panel-icon">
          <FontAwesomeIcon
            icon={skill.icon}
            style={{ color: "white" }}
            size="3x"
          />
          <p style={{ color: "white" }}>{skill.name}</p>
        </div>
      );
    });
  };

  return (
    <main className="container-vh">
      <Header />

      <section className="panel">
        <div className="panel__inner panel__inner--stacked">
          <h2 className="panel__heading">{displayGreeting()}</h2>
          <a
            href="#about"
            className="link-dark link-uppercase"
            aria-label="Scroll For More"
          >
            <i className="link-scroll"></i>
          </a>
        </div>
      </section>

      <section id="about" className="panel">
        <div className="panel__inner panel__inner--light panel__inner--stacked">
          <div className="panel-restricted">
            <h2>About</h2>
            <p className="p-light">
              Hello - I'm Jamie Hammond, a senior software developer based in
              Glasgow, Scotland. I work with a great team at the SAS Institute
              where I build innovative software, I am a huge fan of creating the
              best user experience possible for users.
            </p>
            <p className="p-light">
              When I'm not coding you can find me playing basketball, boxing,
              drinking coffee or eating.
            </p>
          </div>
        </div>
      </section>

      <section id="portfolio" className="panel">
        <div className="panel__inner panel__inner--light panel__inner--stacked">
          <h2>Portfolio</h2>

          <div className="panel-graphic-container">
            <div className="panel-card">
              <a
                href="https://gymbuddy.digital"
                target="_blank"
                rel="noreferrer"
              >
                <h2>GymBuddy</h2>
              </a>
              <p>
                GymBuddy is a fitness app built using React Native, NodeJS and
                MongoDB.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="skills" className="panel">
        <div className="panel__inner panel__inner--stacked">
          <h2 className="h-light">Skills</h2>
          <div className="panel-graphic-container">{displaySkills()}</div>
        </div>
        <Footer />
      </section>
    </main>
  );
};

export default App;
